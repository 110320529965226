import axios from "axios";
import { useEffect, useState } from "react";
import { host } from '@src/app/globals';


const useListAxiosGet = (getUrl) => {
    const [List, setList] = useState([])

    useEffect(() => {
        const getLessonContent = async () => {
            var response = await axios.get(host + getUrl)
            return (response?.data ?? [])
        }

        const setLocalState = async () => {
            var State = await getLessonContent()
            setList(State)
        }
        setLocalState()
    }, [getUrl])

    return ([List, setList])
};

export default useListAxiosGet;

