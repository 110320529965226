import MyRadioBtns from '@shared/UI/Inputs/MyRadioBtns/MyRadioBtns';
import React from 'react';
import cl from './DifficultInput.module.css'

const DifficultInput = ({ label, defaultCheckedElement, selectEvent,  radioArray, disabled }) => {
    return (
        <MyRadioBtns
            label={label}
            defaultCheckedElement={defaultCheckedElement}
            selectEvent={selectEvent}
            InputWrapClassName={cl.DifficultInput}
            radioArray={radioArray} 
            disabled = {disabled}
            />
    );
};

export default DifficultInput;