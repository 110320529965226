'use client'
import { useState, useEffect } from 'react';
import { createPortal, ReactDOM } from 'react-dom';

const Portal = ({ children }) => {
    const [container] = useState(() => { var portalWrap = document.createElement('div'); portalWrap.id = 'portalWrap'; return portalWrap; });

    useEffect(() => {
        if (typeof window !== 'undefined') {
            document.body.appendChild(container);
            const element = document.getElementById('portalWrap');
            return () => {
                if (element && element.parentNode) {
                element.parentNode.removeChild(element);
            }
            };            
        }
    }, [])

    return createPortal(children, container);
};

export default Portal;