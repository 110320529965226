import React from 'react';
import cl from './OverlayPopup.module.css'
import { Portal } from '@shared/hoc';

const OverlayPopup = ({children, onClose, isOpened}) => {

    if (!isOpened) {
        return null;
    }

    return (
        <Portal>
            <div className={cl.overlayPopup} role='dialog'>
                <div
                className={cl.overlay}
                role='button'
                tabIndex={0}
                onClick={onClose}
                />
                {children}
            </div>
        </Portal>
    );
};

export default OverlayPopup;