'use client'
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from '@shared/UI/Inputs/HookForm';
import { usePassEncrypt } from '@shared/hooks';
import { api } from '@src/app/api';
import { AcceptButton } from '@shared/UI/Buttons';
import { MainPopup } from '@shared/UI/Popups';
import { ListVertical, MainWrap } from '@shared/UI/Layouts';
import { DefaultWrapper } from '@shared/UI/Wrappers';
import useDeviceInfomation from '@shared/hooks/AuthHooks';
import { EditProfileForm } from '../PersonalInormation/styled/ProfileStyles'; //повторяеn файлы в wigets
import { useParams, usePathname, useRouter } from 'next/navigation';


const PasswordRecovery = () => {
    const [SendOnMail, setSendOnMail] = useState(false)
    const [redirect, setRedirect] = useState(null)
    const router = useRouter()
    // const [success, setSuccess] = useState(location.state)
    const device = useDeviceInfomation()
    const location = usePathname();
    const { register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'OnInput' });

    const passEncrypt = usePassEncrypt()

    const SetNewPassWord = (data) => {
        setSendOnMail(true);
        data.newpassword !== data.newPasswordConffirm
            ? setError('newPasswordConffirm', { validate: '' })
            : (() => {
                // ---------------------------------------
                api.post(`/user/confirmation/${location.split('/').pop()}`, {
                    password: passEncrypt(data.newpassword),
                    device_type_id: device.type,
                    platform: '123',
                    geo: device.geo == {} ? "" : JSON.stringify(device.geo),
                    system: device.system
                })
                    .then(result =>
                        setRedirect(`/confirmation/SuccessRecovery`))
                    .catch(error => {
                        console.log(error)
                        if (error.response.status === 401) {
                            setError('password', { type: 'custom', message: 'Неверный пароль' });
                        }
                        if (error.response.status === 403) {
                            setError('password', { type: 'custom', message: 'Недействительная ссылка' });
                        }
                    })
            })()
    }
    return (
        // <DefaultWrapper width={'100%'}></DefaultWrapper>
        redirect !== null ?
            router.push('/login?change=success&message=Ваш пароль успешно сброшен, пожалуйста авторизуйтесь')
            :
            <MainWrap width={'100%'}>
                <DefaultWrapper>
                    <EditProfileForm onSubmit={handleSubmit(SetNewPassWord)} >
                        <ListVertical className={'account_Data'}>
                            <h3>Введите новый пароль</h3>
                            <InputValidateUseForm
                                title={'Новый пароль'}
                                type={'password'}
                                error={errors?.newpassword}
                                validateSet={register("newpassword", {
                                    required: "Пожалуйста введите новый пароль.",
                                    // validate: value => value == watch('newPasswordConffirm') || 'пароли не ссвпадают' 
                                })}
                            />
                            <InputValidateUseForm
                                title={'Подтверждение пароля'}
                                type={'password'}
                                error={errors?.newPasswordConffirm}
                                validateSet={register("newPasswordConffirm", {
                                    required: "Пожалуйста введите новый пароль ещё раз",
                                    validate: value => value == watch('newpassword') || 'пароли не совпадают'
                                })}
                            />
                            <AcceptButton type={'submit'} title={'Сменить'} />
                        </ListVertical>
                    </EditProfileForm>
                    <MainPopup
                        isOpened={SendOnMail}
                        onClose={e => setSendOnMail(false)}
                        title={'!!!'}
                        showHeader={true}>
                        Письмо с подтверждением смены пароля отправлено вам на электроную почту!!
                        <AcceptButton title={'Отлично'} onClick={() => setSendOnMail(false)}></AcceptButton>
                    </MainPopup>
                </DefaultWrapper >
            </MainWrap >
    );
};
export default PasswordRecovery;