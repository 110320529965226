'use client'
import React from 'react';
import { useSearchParams, useRouter, usePathname } from "next/navigation";
const useSetParamsUrl = () => {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const { replace, push } = useRouter();

    function updateSearchParam(key, value) {
        const params = new URLSearchParams(searchParams);
        if (value) {
            // set the search parameter if value is not empty        
            params.set(key, value)
        } else {
            params.delete(key)
        }
        return `${pathname}?${params.toString()}`
    }
    function updateSearchParamForCurrentPage(key, value, replase) {   
        const newUrl = updateSearchParam( key, value )
        replase == true ?
            replace(newUrl)
            :
            push(newUrl)
    }
    return ([updateSearchParam, updateSearchParamForCurrentPage])
}
export default useSetParamsUrl