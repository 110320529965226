"use client"
import React from 'react';
import cl from './DefaultWrapper.module.css';
import cn from 'classnames'

const DefaultWrapper = ({children, className, ...props}) => {

    return (
        <div className={cn(cl.defaultWrapper, className)} {...props}>
            {children}
        </div>
    );
};

export default DefaultWrapper;