import React from 'react';
import InputElement from 'react-input-mask';
import cl from './MyInput.module.css'

const MyInputMask = ({ validateSet, ...props }) => {
    // const {defaultValue , ...allValidate} = validateSet
    const {defaultValue , ...otherrops} = props
    return (
        <InputElement
            className={cl.myinput}
            {...otherrops}
            {...validateSet}
            // defaultValue=''
        />
    );
};

export default MyInputMask;