import { api } from "@src/app/api";
import { useEffect, useState } from "react";

const useListWithApiPost = (getUrl) => {
    const [List, setList] = useState([])

    useEffect(() => {
        const getLessonContent = async () => {
            var response = await api.get(getUrl, { fromServer: typeof window === 'undefined' })
            return (response?.data ?? [])
        }

        const setLocalState = async () => {
            var State = await getLessonContent()
            setList(State)
        }
        setLocalState()

    }, [getUrl])

    return ([List, setList])
};

export default useListWithApiPost;

