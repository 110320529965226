export default function formatDate(fullTime) {

    function minute(i) {
        if (i < 10) {
            i = '0' + i
        }
        return i;
    }

    if (fullTime) {
        var data = new Date(fullTime)
        var h = data.getHours()
        var m = minute(data.getMinutes())
        return h + ':' + m
    } else {
        var data = new Date()
        var dataYear = data.toISOString().split('T')[0]
        var h = data.getHours()
        var m = minute(data.getMinutes())
        return dataYear + ' ' + h + ':' + m + ':' + '00'
    }
};
