import React, { useEffect, useState } from 'react';

const useIterator = (maxCount, CurrtentCount) => {
    const [ElList, setElList] = useState(null)

    useEffect(() => {
        if (maxCount < CurrtentCount) {
            setElList('Error: maxValue > currentValue')
        } else {
            var Array = []
            for (let i = 0; i < maxCount; i++) {
                i < CurrtentCount-1
                    ? Array.push(1)
                    : Array.push(0)
            }
            setElList(Array)
        }
    }, [maxCount, CurrtentCount])
    return (ElList);
};
export default useIterator;