import React from 'react';
import MyInput from '../MyInput/MyInput';

const MyInputErrorAndTitle = ({title, error, onChange, WrapClassName, InputClassName, ...props}) => {
    return (
            <div className={WrapClassName}>
                {title && <p>{title}</p>}
                {error && <p>{error}</p>}
                <MyInput
                    className={InputClassName}
                    onChange={onChange}                   
                    {...props}
                />
            </div>
    );
};

export default MyInputErrorAndTitle;