import { ContentHorizontal } from "@ui/Layouts";
import styled from "styled-components";


export const ProfileTextBold = styled.p`   
    font-size  : 14px; 
    font-weight: 500;
    color: #163C85;
`

export const EditProfileForm = styled.form`   
    max-width: 765px;
    width: 80%;
    margin: 0 auto;
`
export const ProfileHeader = styled.h3`   
    font-size  : 20px; 
    font-weight: 600;
    color: #163C85;
`

export const ProfileText = styled.p`   
    font-size  : 14px; 
    font-weight: 400;
    color: #163C85;
`


export const ContentHeader = styled(ContentHorizontal)`
font-size: 22px;
    width: 100%;
    font-weight: 500;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    color: var(--base-blue-color);
    justify-content: space-between;
    align-items: center;
`

// export const ListVerticalStart = styled(ListVertical)`
// justify-content: flex-start;
//     align-items: flex-start;
// `
// export const ListVerticalNoGap = styled(ListVertical)`
// gap: 0;
// `
// export const ChangerWrap = styled(ContentHorizontal)`
//   background-color: #E8F1FF;
//   border-radius: 16px;
//   justify-content: space-between;
//   width: 100%;
//   padding: 18px 20px;
//   &>a{width: fit-content}
//   `

// export const SpacedApartWrap = styled(ContentHorizontal)`
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
// `

