'use client'
import React, { useEffect, useState } from 'react';
// import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { api, getUserData } from '@src/app/api';
import useDeviceInfomation from '@shared/hooks/AuthHooks';
import { observer } from 'mobx-react-lite';
import userStore from '@shared/store/userStore';
import { MainWrap } from '@shared/UI/Layouts';
import { DefaultWrapper } from '@shared/UI/Wrappers';
import { Loader } from '@shared/UI/Loaders';
import { TbSquareRoundedCheckFilled } from "react-icons/tb";
import { RedirectButton, ConfirmMain, ConfirmDefault } from './ui';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { invite_group } from '@src/app/globals/cookie'; 
import { HocInviteRoute } from '@shared/hoc';
import Cookies from 'universal-cookie';

const Verification = observer(({ emailChangeSecond }) => {

    const [isResult, setIsResult] = useState(false);
    const [hasConfirm, setHasConfirm] = useState(false);
    const [confirmationType, setConfirmationType] = useState(null);
    const [confirmationLinkError, setConfirmationLinkError] = useState(null);
    const location = usePathname()
    const searchParams = useSearchParams()
    const device = useDeviceInfomation();
    const router = useRouter()

    const cookies = new Cookies();
    const goHome = () => router.push('/home')
    const goLogin = (param) => {
        router.push('/login', { state: param })
    }


    var timer = null
    //Получение случайной строки для подтверждения регистрации
    var confirmURL = location.split('/')[location.indexOf('confirmation') + 1]
    const str = location.split('/')

    useEffect(() => {
        checkVerification()
        // return () => clearTimeout(timer)
    }, [])

    const sendNewMail = () => {
        api.post(`user/retryregconfirmstring/${confirmURL}`, {
            device_type_id: device.type,
            platform: '123',
            geo: device.geo == {} ? "" : JSON.stringify(device.geo),
            system: device.system
        })
            .then((result) => {

            })
            .catch((error) => {
                if (error.response.status === 403) {
                    console.log('пользователь не найден')                  
                }
                if (error.response.status === 429) {
                    console.log('ссылка недействительна')  
                }

            })
    }

    const checkVerification = () => {
        api.post(`user/confirmation/${emailChangeSecond ? str[str.length - 1] : confirmURL}`, {
            device_type_id: device.type,
            platform: '123',
            geo: device.geo == {} ? "" : JSON.stringify(device.geo),
            system: device.system
        })
            .then(result => {
                cookies.set('token', result.data.token, { path: '/' });
                result.status == 200 && setIsResult(true)
                setConfirmationType(result.data.type)
                emailChangeSecond === undefined && getUserData()
                    .then((result) => {
                        cookies.set('userId', result.data.id, { path: '/' });
                        userStore.changeUser(result.data);
                        userStore.signIn()
                        setIsResult(true)
                        timer = setTimeout(Redirect, 5000)
                        userStore.getUserPhoto(result)
                    })
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    setHasConfirm(true)
                    setIsResult(true)
                    return
                }
                if (error.response.status === 429) {
                    setConfirmationLinkError(true)

                    // return goLogin({ error: { code: 1, message: 'Ссылка устарела, войти ещё раз и отправьте ссылку повторно' } })

                }
                // return goLogin({ error: { code: 1, message: 'Неизвестная ошибка' } });
            })
    }

    const Redirect = () => {
        if (searchParams.get('invite_group') !== undefined || searchParams.get('invite_group') !== null && isResult) {
            invite_group(searchParams.get('invite_group'))
            return router.push('/invite/group')
        }
        if (!hasConfirm && isResult) {
            return goHome()
        }
        if (hasConfirm && isResult) {
            return goLogin()
        }
    }
    const GetNewMailConfirmation = () => {
        return (
            <>
                <p>Cсылка более недействительна</p>
                <p>Выслать ссылку повторно</p>
                <RedirectButton style={{ height: 'auto' }} onClick={() => { sendNewMail() }}>
                    Выслать
                </RedirectButton>
            </>
        )
    }



    const RedirectVerification = () => {
        return (
            <>
                <TbSquareRoundedCheckFilled size={'42px'}
                    color={hasConfirm ? '#df9f09' : '#39AA95'} />
                {
                    !hasConfirm && isResult
                        ?
                        confirmationType === 'mailChange'
                            ? <p>Ваша почта успешно изменена</p>
                            : confirmationType === 'password'
                                ? <p>Ваша пароль успешно изменён</p>
                                : <p>Вы успешно прошли регистрацию и будете перенаправлены на домашнюю страницу через 5 секунд</p>
                        : <p>Ваша почта успешно подтверждена. Перейдите на страницу входа</p>
                }
                <RedirectButton style={{ height: 'auto' }} onClick={() => Redirect()}>{!hasConfirm && isResult ? 'Перейти на главную' : 'Перейти'}</RedirectButton>
            </>
        )
    }

    const AwaitVerification = () => {
        return (
            <>
                <div>
                    <Loader style={{ width: '50px', height: '50px' }} />
                </div>
                <p>Ожидайте...</p>
            </>
        )
    }

    return (
        <ConfirmMain >
            <ConfirmDefault >
                {
                    confirmationLinkError
                        ? <GetNewMailConfirmation />
                        : isResult
                            ? <RedirectVerification />
                            : <AwaitVerification />
                }
            </ConfirmDefault>
        </ConfirmMain>

    );
});

export default Verification;