import React from 'react';
import cl from './MyInput.module.css';
import { HiMagnifyingGlass } from 'react-icons/hi2'

const MyInputSearch = (props) => {
    return (
            <div className={cl.search}>
                <HiMagnifyingGlass />
                <input className="mainSearchInput" {...props} />
            </div>
    );
};

export default MyInputSearch;