import { useEffect, useState } from "react"

export const useGetUsefulSpace = (ref) => {
   const [usefulSpace, setUsefulSpace] = useState({ height: 0, width: 0 })

    useEffect(() => {
        const elem = window.getComputedStyle(ref.current)

        const paddingHeight = parseFloat(elem['paddingTop']) + parseFloat(elem['paddingBottom'])
        const paddingWidth = parseFloat(elem['paddingLeft']) + parseFloat(elem['paddingRight'])
        const width = Math.ceil(ref?.current?.clientWidth - paddingWidth)
        const height = Math.ceil(ref?.current?.clientHeight - paddingHeight)
        setUsefulSpace({ ...usefulSpace, height: height, width: width})

    }, [ref.current])

    return usefulSpace
}
