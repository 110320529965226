
import { useEffect, useState } from "react";
import { api } from '@src/app/api'

const useStreamListWithApi = (defaultList, courseId, addUrl, deleteUrl, updateUrl) => {
    const [List, setList] = useState()
    useEffect(() => {
        setList(defaultList ?? [])
    }, [defaultList, courseId])

    const AddElement = (newElementData) => {
        api.post(addUrl, { ...newElementData })
            .then((result) => {
                if (result.data == null) {

                } else {
                    var resultList = [...List]
                    result.data?.messages
                        ? resultList.push(result.data?.messages[0] ?? result.data?.messages)
                        : resultList.push(result.data)
                    setList(resultList)
                }
            })
    }


    const RemoveElById = (id) => {
        api.delete(deleteUrl + id)
            .then((result) => {
                setList(List.filter(element => element.id !== id))
            })
    }


    const UpdateElements = (updatedElementData) => {
        api.post(updateUrl, { fields: updatedElementData })
            .then((result) => {
                var newList = []
                List.map(elementData => {
                    elementData.id == updatedElementData.id
                        ? elementData = newList.push({ ...elementData, ...updatedElementData })
                        : newList.push(updatedElementData)
                });
                setList(newList)
            })
    }

    return ({
        list: List,
        add: AddElement,
        remove: RemoveElById,
        update: UpdateElements,
    })


};

export default useStreamListWithApi;

