import React from 'react';
import cl from './NavigateSection.module.css'
import cn from 'classnames'
import {NavigateSectionWrap} from './ui'
 

const NavigateSection = ({ children, className }) => {

    return (
        <NavigateSectionWrap className={cn(cl.navigateSection, className)}>
            {children}
        </NavigateSectionWrap>
    );
};

export default NavigateSection;