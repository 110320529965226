import React from 'react';
import cl from './InputValidateCheckBox.module.css'
import cn from 'classnames';
const InputValidateCheckBox = ({ InputClass, CBoxClass, CBoxList, validateSet, error, title }) => {
    return (
        <>
            <div className={cn( cl.checkBoxes, InputClass)}>
                {title ? <h2>{title}</h2> : null}
                <li>
                    {CBoxList?.map(element => (
                        <>
                            <div className={CBoxClass}>
                                <input
                                    id={element.id}
                                    key={element.id}
                                    type="checkbox"
                                    value={element.value}
                                    {...validateSet}
                                />
                                <label htmlFor={element.id}>
                                    <span>{element.title ?? element.value}</span>
                                </label>
                            </div>
                        </>
                    ))
                    }
                </li>

            </div>
            {error && <div className={cl.ValidateError}> {error.message || "Пожалуйста выберите параметр"}</div>}
        </>
    );
};

export default InputValidateCheckBox;