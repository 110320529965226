import React from 'react';
import cl from './RadioHookForm.module.css'
const RadioHookForm = ({ validateSet, radioArray = [], error }) => {
    return (
        <>
            {radioArray?.map(radioElem => (
                <div key = {radioElem.id} className={cl.radio}>
                    <label>
                        <input
                            type="radio"
                            value={radioElem.value}
                            {...validateSet} />
                        <span>{radioElem.title}</span>
                    </label>
                </div>
            ))}
            {error && <div className={cl.ValidateError}> {error.message || "Пожалуйста выберите параметр"}</div>}
        </>

    );
};

export default RadioHookForm;