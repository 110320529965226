import { useEffect, useState } from "react";
import { api } from '@src/app/api';

const useApiUpdateState = (elementData, updateUrl) => {
    const [LocalElementData, setLocalElementData] = useState()

    useEffect(() => {
        setLocalElementData(elementData)
    }, [elementData])
    const fetchUpdateElement = (updatedElementData) => {
        api.put(updateUrl, { ...updatedElementData })
            .then((result) => {
                setLocalElementData({ ...LocalElementData, ...updatedElementData })
            })
    }

  

    return ([LocalElementData,fetchUpdateElement, setLocalElementData, ])


};

export default useApiUpdateState;

