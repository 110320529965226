'use client'
import { token, invite_group } from "@src/app/globals";
import { useParams, usePathname, useRouter, useSearchParams, redirect } from "next/navigation";
import { useEffect } from "react";
// import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import Router from "next/router";

const HocInviteRoute = () => {
    const router = useRouter()

    const params = useParams()
    const location = usePathname()
    const cookies = new Cookies()
    const token = cookies.get('token')
    const searchParams = useSearchParams()
    //прарметр в ссылке через ?
    const url = params.gpoupLink
    //параметр в ссылке
    const urlParam = searchParams.get('invite_group')
    const cookiesInvite = cookies.get('invite_group')
    useEffect(() => {
        if ((token == undefined || token == null) && urlParam) {
            cookies.set('invite_group', urlParam, { path: '/' })
            router.push(`/login`)
        } else if (token && (url != null || urlParam != null || cookiesInvite != null)) {
            if (urlParam !== null && urlParam !== undefined) {
                //Если в ссылке есть параметр invite_group через ?
                cookies.set('invite_group', urlParam, { path: '/' })
            } else if (url !== undefined && url !== null) {
                //Если в ссылке есть строка после/
                cookies.set('invite_group', url, { path: '/' })
            }
            router.push(`/mylearn/mycourses`, undefined, { shallow: true })
            //Если есть кука на приглашение
        } else {
            if (url !== null && url !== undefined) { cookies.set('invite_group', url, { path: '/' }) }
            router.push(`/login`)
        }
    }
    , [])
};

export default HocInviteRoute;