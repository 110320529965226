'use client'
import { observer } from 'mobx-react-lite'
import userStore from '@shared/store/userStore';
import { LoaderBlueFullView } from '@shared/UI/Loaders';
// import { Navigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { api } from '@src/app/api';
import useDeviceInfomation from '@shared/hooks/AuthHooks';
import { PasswordRecovery } from '@sheets/PersonalArea/PasswordRecovery';
import SetNewEmail from '@sheets/PersonalArea/EmailChange/SetNewEmail';
import PageConfirmation from '@sheets/Confirmation/PageConfirmation';
import { usePathname, useRouter, useParams } from 'next/navigation';
import Cookies from 'universal-cookie';
import HocInviteRoute from './HocInviteRoute'
import { useSearchParams } from 'next/navigation'

const HocConfirmRoute = ({ serverConfirmationString, serverModificator, isInvitePage }) => {
    const device = useDeviceInfomation()
    const [renderResult, setRenderResult] = useState(serverConfirmationString)
    const location = usePathname()
    const router = useRouter()
    const searchParams = useSearchParams()
    const searchInvitGroup = searchParams.get('invite_group')
    const params = useParams()
    const cookies = new Cookies()
    useEffect(() => {
        CheckAndSet()
    }, [])

    const CheckAndSet = async () => {
        const str = location.split('/')
        if (isInvitePage && (params?.gpoupLink === null || params?.gpoupLink === undefined)){
            setRenderResult('invite_group')
        }
        else if (isInvitePage && params?.gpoupLink !== null && params?.gpoupLink !== undefined ) {
            setRenderResult('invite_group')
        } else {        
            var cs = await api.post('/user/CheckString/' + str[str.length - 1], {
                device_type_id: device.type,
                platform: '123',
                geo: device.geo == {} ? "" : JSON.stringify(device.geo),
                system: device.system
            })
                .then((result) => {
                    setRenderResult('error'); return (true)
                }
                )
                .catch((error) => { if (error.response.status === 403) { setRenderResult('error'); return (false) } })

            if (cs === true) {
                location.indexOf('/confirmation/RecoveryPassword') > -1
                    ?
                    setRenderResult('RecoveryPassword')
                    :
                    location.indexOf('/SuccessRecovery') > -1
                        ?
                        setRenderResult('SuccessRecovery')
                        :
                        location.indexOf('/EmailChangeFirst') > -1
                            ?
                            setRenderResult('EmailChangeFirst')
                            :
                            location.indexOf('/EmailChangeSecond') > -1
                                ?
                                setRenderResult('EmailChangeSecond')
                                :
                                location.indexOf('/confirmation/ChangePassword') > -1
                                    ?
                                    setRenderResult('PasswordChange')
                                    :
                                    location.indexOf('/confirmation') > -1
                                        ?
                                        setRenderResult('Confirm')
                                        :
                                        ConfirmChange()
            }
        }


    }

    const ConfirmChange = () => {
        api.post(`/user${location}`, {
            device_type_id: device.type,
            platform: '123',
            geo: device.geo == {} ? "" : JSON.stringify(device.geo),
            system: device.system
        })
            .catch(error => {
                setRenderResult('error');
                if (error.response.status === 403) {
                }
            })
            .then(result => {
                if (result?.data?.type !== null) {
                    setRenderResult(result?.data?.type)
                }
                else {
                    setRenderResult(serverModificator)
                }
            })
    }

    const ChangePasswordApi = () => {
        const string = location.split('/confirmation/ChangePassword')
        return api.post(`/user/confirmation${string[1]}`, {})
    }
    switch (renderResult) {
        case 'RecoveryPassword':
            return (<PasswordRecovery />);
        case 'Confirm':
            return (<PageConfirmation />);
        case 'PasswordChange':
            ChangePasswordApi()
                .then((response) => {
                    userStore.changeJwt(response.data.token)
                    router.push('/profile/password?change=success&message=Пароль успешно изменен')
                })
                .catch(() => { return console.log('ошибка') })
            break
        case 'EmailChangeFirst':
            return (<SetNewEmail />);
        case 'EmailChangeSecond':
            return (<SetNewEmail emailChangeSecond={true} />);
        case 'invite_group':
            return (<HocInviteRoute />);    
        case 'error':
            return <p>Ссылка более недействительна</p>;
        case null:
            return setRenderResult('Неизвестная ошибка');
    }
};
export default observer(HocConfirmRoute);