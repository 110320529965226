import ChangeInput from './Inputs/ChangeInput/ChangeInput';
import MyInput from './Inputs/MyInput/MyInput';
import MyTextarea from './Inputs/MyTextarea/MyTextarea';
import MyInputDate from './Inputs/MyInput/MyInputDate';
import MyInputSearch from './Inputs/MyInput/MyInputSearch';
import MyInputMask from './Inputs/MyInput/MyInputMask';
import MyInputErrorAndTitle from './Inputs/MyInputErrorAndTitle/MyInputErrorAndTitle';
import MyRadioBtns from './Inputs/MyRadioBtns/MyRadioBtns';
import CheckBox from '@shared/UI/Inputs/CheckBox';
import DifficultInput from './Inputs/DifficultInput/DifficultInput';

export {
    MyInput,
    MyTextarea,
    ChangeInput,
    MyInputDate,
    MyInputSearch,
    MyInputMask,
    MyInputErrorAndTitle,
    MyRadioBtns,
    CheckBox,
    DifficultInput
}