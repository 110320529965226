import HocPrivateRoute from "./HocPrivateRoute";
import Portal from './Portal'
import HocConfirmRoute from './HocConfirmRoute'
import HocDirectRoute from "./HocDirectRoute";

export {
    HocPrivateRoute,
    HocConfirmRoute,
    HocDirectRoute,    
    Portal,
} 