import styled from "styled-components";
import { ListVertical } from '@shared/UI/Layouts';

export const NavigateSectionWrap = styled(ListVertical)`
  box-shadow: var(--default-style-shadow);
  border: 0;
  padding: 0;
  width: 100%;
  max-width: 250px;
  min-width: 200px;
  position: sticky;
  top: 90px;
  bottom: 40px;
  overflow: hidden;
  gap: 0 !important;
  height: min-content;
  border-radius: 15px ;
  background-color: #fff;

  div {
  width: 100%;
  height: 54px;
  padding: 0;
}

span {
  font-weight: 600;
}
`
