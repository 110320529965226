import { MainWrap } from "@shared/UI/Layouts";
import { DefaultWrapper } from "@shared/UI/Wrappers";
import styled from "styled-components";

export const RedirectButton = styled.button`   
    width: 100%;
    height: min-content;
    /* height: min-content; */
    height: auto;
    padding: 14px 40px;
    text-wrap: nowrap;
    background: #DEEAFF;
    border-radius: 14px;
    color: #246FFB;
    font-weight: 600;

    button { 
        height: auto;
    }


    &:hover {
        background: #C6DAFF;
    }

    &:active {
        background: #A7C5FD;
    }
`

export const ConfirmMain = styled(MainWrap)` 
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
`

export const ConfirmDefault = styled(DefaultWrapper)` 
 width: 376px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 32px;
    font-weight: 600;
    color: #163C85;

    p {
        font-size: 18px;
    }
`