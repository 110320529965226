const useDurationParse = (time) => {
    const TransformDuration = (time) => {
        var TimeArray = time.split(':')
        if (TimeArray.length > 0) {
            time = TimeArray[0] * 60 + TimeArray[1] * 60 + TimeArray[2] 
        }       
            var transformTime =
            time ?
                (Number(TimeArray[0]) !== 0 ? Number(TimeArray[0])+ 'ч' : '') + ' ' + 
                (Number(TimeArray[1]) !== 0 ? Number(TimeArray[1])+ 'мин ' : '') + ' ' + 
                (Number(TimeArray[2]) !== 0 ? Number(TimeArray[2])+ 'сек ' : '') + ' '                
                : 'нет'  
        return transformTime
    }

    return TransformDuration
};

export default useDurationParse;